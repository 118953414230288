import { __assign } from "tslib";
import { PublicVideoCards } from '@wix/wix-vod-api-site-based/dist/src/public/services/video-cards/video-cards';
import { parseCards } from '@wix/wix-vod-shared/dist/src/common/parsers/cards/cards';
import { toError } from 'api/parsers/transport-error';
import { publicCatalogServiceConfig } from 'api/config/catalog-service';
import { isGraphAPIEnabled } from 'shared/selectors/experiments';
import { PublicVideoCardsService } from '@wix/wix-vod-gql-api/dist/public/video-cards/video-cards';
var oldService = new PublicVideoCards(publicCatalogServiceConfig);
export { oldService as service };
export var getVideoCards = function (channelId, videoId) { return function (dispatch, getState, _a) {
    var createService = _a.createService;
    var state = getState();
    var promise = isGraphAPIEnabled(state)
        ? createService(PublicVideoCardsService).getList(videoId)
        : oldService.getList(channelId, videoId);
    return promise
        .then(function (response) { return (__assign(__assign({}, response), { data: parseCards(response.data) })); })
        .catch(toError);
}; };
