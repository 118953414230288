import { getNumberOfRows, getVideosInRowCount, isGridLayout, isStripLayout, } from 'shared/selectors/app-settings';
export var getVideosCount = function (_a) {
    var isMobile = _a.isMobile, styleParams = _a.styleParams;
    if (isMobile) {
        return;
    }
    var state = { appSettings: styleParams };
    if (isGridLayout(state) || isStripLayout(state)) {
        return getNumberOfRows(state) * getVideosInRowCount(state);
    }
};
