import _ from 'lodash';
import { APP_SETTINGS_VERSION_LATEST } from '@wix/wix-vod-constants/dist/app-settings/versions';
import { isDemoChannel } from 'shared/utils/channel-helpers';
import { getChannelId } from 'widget/utils/app-settings';
export var isLatestVersion = function (appSettings) {
    return _.get(appSettings, 'numbers.settingsVersion') === APP_SETTINGS_VERSION_LATEST;
};
export var isNewInstallation = function (appSettings) {
    var channelId = getChannelId(appSettings);
    return !channelId || isDemoChannel(channelId);
};
