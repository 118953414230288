var _a;
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NAMES as CHANNEL_VIDEOS_LIST } from 'shared/redux/server/actions/channel/videos/list-public';
import { APP_SETTINGS_UPDATE } from 'widget/redux/client/actions/app-settings';
import { getChannelId } from 'widget/utils/app-settings';
var defaultState = null;
var oldChannelId = null;
function resetOnChannelIdChange(state, _a) {
    var payload = _a.payload;
    var channelId = getChannelId(payload);
    if (channelId && oldChannelId && oldChannelId !== channelId) {
        oldChannelId = channelId;
        return defaultState;
    }
    return state;
}
function saveVideoId(state, _a) {
    var payload = _a.payload;
    if (state) {
        return state;
    }
    return _.get(payload, 'response.data.result[0]', null);
}
export default handleActions((_a = {},
    _a[CHANNEL_VIDEOS_LIST.SUCCESS] = saveVideoId,
    _a[APP_SETTINGS_UPDATE] = resetOnChannelIdChange,
    _a), defaultState);
