import { __read, __spread } from "tslib";
import _ from 'lodash';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { getVODExperiments } from 'api/public/experiments/experiments';
import { parseExperimentsFromUrl } from 'shared/utils/experiments';
import { getFullSiteUrl } from 'widget/redux/client/hydrated-data/hydrated-data';
import { createAsyncProxy } from 'shared/worker/lib';
var ACTIONS = createAsyncActions('SERVER.EXPERIMENTS');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
export var getExperiments = createAsyncProxy('experiments.get', function () {
    return function (dispatch, getState) {
        dispatch(START());
        var state = getState();
        return Promise.all([
            getVODExperiments(state),
            __SERVER__ ? null : parseExperimentsFromUrl(getFullSiteUrl(state)),
        ])
            .then(function (args) { return _.merge.apply(_, __spread(args)); })
            .then(function (experiments) {
            dispatch(SUCCESS({}, experiments));
            return experiments;
        }, function (response) {
            dispatch(FAIL({}, response, {
                analytics: { type: 'error', name: 'shared.experiments.get' },
            }));
        });
    };
});
