var _a;
import { __assign } from "tslib";
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NAMES as GET_EXPERIMENTS } from 'shared/redux/server/actions/experiments/get';
var defaultExperiments = {};
export default handleActions((_a = {},
    _a[GET_EXPERIMENTS.SUCCESS] = function (state, _a) {
        var response = _a.payload.response;
        return (__assign(__assign({}, defaultExperiments), response));
    },
    _a[GET_EXPERIMENTS.FAIL] = _.constant(defaultExperiments),
    _a), null);
