import { __assign } from "tslib";
import _ from 'lodash';
import getQueryParams from 'shared/utils/get-query-params';
export var parseExperimentsFromUrl = function (siteUrl) {
    var queryParams = getQueryParams(siteUrl);
    var _a = queryParams.experiments, experiments = _a === void 0 ? '' : _a;
    return _.reduce(experiments.split(','), function (acc, name) {
        var _a;
        if (name) {
            return __assign(__assign({}, acc), (_a = {}, _a[name] = 'true', _a));
        }
        return acc;
    }, {});
};
