var _a;
import { __assign } from "tslib";
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { demoChannel } from 'shared/constants/demo-channel';
import { APP_SETTINGS_UPDATE } from 'widget/redux/client/actions/app-settings';
import { NAMES as CHANNEL_GET } from 'widget/redux/server/actions/channel/get-public';
import { getChannelId } from 'widget/utils/app-settings';
export default handleActions((_a = {},
    _a[APP_SETTINGS_UPDATE] = function (state, _a) {
        var payload = _a.payload;
        if (!payload.fonts) {
            return state;
        }
        var prevChannelIdFromSettings = state.prevChannelIdFromSettings;
        // For a case when user had channel and then removed it, so it falls back to demoId
        // When any setting in appSettings changes, it retries to fetch deleted channel, that
        // said goes into finite loop of GET/FAIL actions.
        var nextChannelId = getChannelId(payload);
        if (prevChannelIdFromSettings === nextChannelId) {
            return {
                currentChannelId: state.currentChannelId || demoChannel.id,
                prevChannelIdFromSettings: prevChannelIdFromSettings,
            };
        }
        prevChannelIdFromSettings = nextChannelId;
        return {
            currentChannelId: prevChannelIdFromSettings || state.currentChannelId || demoChannel.id,
            prevChannelIdFromSettings: prevChannelIdFromSettings,
        };
    },
    _a[CHANNEL_GET.FAIL] = function (state, _a) {
        var payload = _a.payload;
        if (_.get(payload, 'response.error.code') === -7710) {
            return __assign(__assign({}, state), { currentChannelId: demoChannel.id });
        }
        return state;
    },
    _a), { currentChannelId: null, prevChannelIdFromSettings: undefined });
