/* eslint-disable camelcase */
import { __awaiter, __generator } from "tslib";
import { PublicChannel } from '@wix/wix-vod-api-site-based/dist/src/public/services/channel/channel';
import { toError } from 'api/parsers/transport-error';
import { keysToCamelCase } from '@wix/wix-vod-shared/dist/src/common/utils/object';
import { publicCatalogServiceConfig } from 'api/config/catalog-service';
import { CHANNEL_TYPES } from '@wix/wix-vod-constants/dist/common/channel-types';
import { parseChannel } from './parsers';
import { createCtx } from '@wix/wix-vod-shared/dist/src/common/utils/context';
export var createService = function () {
    return new PublicChannel({
        privateServiceConfig: publicCatalogServiceConfig,
    });
};
export var service = createService();
export var ctx = createCtx();
export var getVideoPreviewUrls = function (channelId, instance) {
    // const service = ctx.getValue();
    return service
        .getVideoPreviewUrls(channelId, instance)
        .then(function (response) { return response.data; });
};
export var getMemberInfo = function (channelId, instance, _a) {
    var hydratedMemberInfo = (_a === void 0 ? {} : _a).hydratedMemberInfo;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, _c, error_1;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 3, , 4]);
                    _b = keysToCamelCase;
                    _c = hydratedMemberInfo;
                    if (_c) return [3 /*break*/, 2];
                    return [4 /*yield*/, service.getMemberInfo(channelId, instance)];
                case 1:
                    _c = (_d.sent());
                    _d.label = 2;
                case 2: return [2 /*return*/, _b.apply(void 0, [_c])];
                case 3:
                    error_1 = _d.sent();
                    return [2 /*return*/, toError(error_1.response)];
                case 4: return [2 /*return*/];
            }
        });
    });
};
export var getPublic = function (channelId, _a) {
    var _b = _a === void 0 ? {} : _a, hydratedChannel = _b.hydratedChannel, templateMetaSiteId = _b.templateMetaSiteId;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, _c, channel, custom_cover_url, cover_url, featured_item, channel_type;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _c = hydratedChannel;
                    if (_c) return [3 /*break*/, 2];
                    return [4 /*yield*/, service.getInfo(channelId, null, {
                            params: { metaSiteId: templateMetaSiteId },
                        })];
                case 1:
                    _c = (_d.sent());
                    _d.label = 2;
                case 2:
                    response = _c;
                    try {
                        channel = response.data;
                        custom_cover_url = channel.custom_cover_url, cover_url = channel.cover_url, featured_item = channel.featured_item, channel_type = channel.channel_type;
                        if (!custom_cover_url &&
                            !cover_url &&
                            featured_item &&
                            channel_type !== CHANNEL_TYPES.YOUTUBE_FEED) {
                            channel.cover_url = extractCoverFromVideoItem(channel.featured_item);
                        }
                        return [2 /*return*/, { data: parseChannel(channel) }];
                    }
                    catch (error) {
                        return [2 /*return*/, toError(error.response)];
                    }
                    return [2 /*return*/];
            }
        });
    });
};
var extractCoverFromVideoItem = function (videoItem) {
    return videoItem.custom_cover_url || videoItem.cover_url;
};
